<template>
  <v-container>
    <v-row class="mb-4">
      <h2>ELN (In-Lab) Original Investigation - Plan</h2>
    </v-row>
    <v-form>
      <div class="mb-10">
        <p>A plan must be made for the original investigation that will be done next week.</p>
      </div>
      <div class="mb-4">
        <p class="mb-4">
          1. What is the goal of the original investigation?
          <v-checkbox
            v-for="option in optionsQ1"
            :key="option.value"
            v-model="inputs.question1Values"
            class="mb-4"
            style="margin-left: 20px"
            :label="option.text"
            :value="option.value"
          ></v-checkbox>
        </p>
      </div>
      <div v-for="question in questions" :key="question.inputName">
        <v-row class="mb-4">
          {{ question.text }}
        </v-row>
        <div>
          <s-textarea
            v-model="inputs[question.inputName]"
            class="mb-4"
            outlined
            :counter="true"
            :maxlength="question.maxInputLength"
          />
        </div>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'UCIrvineChem1LDELN2OIPlan',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Values: [],
        question2Value: null,
      },
      optionsQ1: [
        {
          text: 'Determine what compounds are in the essential oil.',
          value: 'answer1',
        },
        {
          text: 'Find the best eluent ratio.',
          value: 'answer2',
        },
        {
          text: 'Determine if the permanganate dip works.',
          value: 'answer3',
        },
      ],
      questions: [
        {
          text: '2. What method will you use in the original investigation? Provide 3 to 6 bulleted procedural steps describing how you will use glassware, equipment, and instrumentation. Additionally, detail the data acquired during fundamental skills and propose the variables/data you will need to collect.',
          inputName: 'question2Value',
          maxInputLength: 1000,
        },
      ],
    };
  },
};
</script>
